.tarjeta-proy{
  width: 18em;
  height: 18em;
  background-position:left;
  background-repeat:no-repeat;
  background-size:cover;
  transition: all .3s ease-in-out;
  .cont-tarj{
    border: 1px solid var(--Color3);
    padding: 1.2em;
    width: 100%;
    height: 100%;
    background:linear-gradient(45deg,hsla(0, 0%, 0%, 0.709) 50%,hsla(0, 0%, 0%, 0.224));
    display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  color: var(--Color4);
  transition: background,border .3s ease-in-out;
    >span{
      width: 100%;
    }
    >p{
      font-size: 14px;
      line-height: 1.2;
      text-wrap: balance;
    }
    >img{
      width: 12em;
      height: 6em;
      border-radius: 5px;
      object-fit: cover;
      margin: .5em 0 .5em 0;
    }
    .tecs-proy{
      display: flex;
      flex-direction: row;
      width: 100%;
      
      >ul{
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        gap: .5em;
        >li{
          list-style: none;
          >i{
            font-size: 1.2em;
          }
        }
      }
  
    }
  }
  .cont-tarj:hover{
    border: 1px solid var(--Color5);
    background-color: #05294b92;
  }
}
.tarjeta-proy:hover{
  background-position: center;
}

@media screen and (min-width: 768px) and (max-width: 1023px){
  .tarjeta-proy{
    width: 15em;
    height: 15em;
    .cont-tarj{
      padding: .5em;
      >span{
        width: 100%;
      }
      >p{
        font-size: 12px;
        line-height: 1.2;
        text-wrap: balance;
      }
      >img{
        width: 12em;
        height: 6em;
        border-radius: 5px;
        object-fit: cover;
        margin: .5em 0 .5em 0;
      }
      .tecs-proy{
        display: flex;
        flex-direction: row;
        width: 100%;
        
        >ul{
          display: flex;
          flex-direction: row;
          justify-content: start;
          align-items: center;
          gap: .5em;
          >li{
            list-style: none;
            >i{
              font-size: 1.2em;
            }
          }
        }
    
      }
  }
  
  }
}