*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.App{
  width: 100%;
  height: 100%;
}

.Contenedor-Principal{
  width: 100vw;
  height: auto;
  background-color: var(--Color1);
}

@keyframes animate {
  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }
  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }
}

.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: var(--Color1);
  overflow: hidden;
}
.background li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 19s linear infinite;
}




.background li:nth-child(0) {
  left: 21%;
  width: 47px;
  height: 47px;
  bottom: -47px;
  animation-delay: 1s;
}
.background li:nth-child(1) {
  left: 22%;
  width: 62px;
  height: 62px;
  bottom: -62px;
  animation-delay: 5s;
}
.background li:nth-child(2) {
  left: 6%;
  width: 46px;
  height: 46px;
  bottom: -46px;
  animation-delay: 2s;
}
.background li:nth-child(3) {
  left: 3%;
  width: 50px;
  height: 50px;
  bottom: -50px;
  animation-delay: 7s;
}
.background li:nth-child(4) {
  left: 58%;
  width: 67px;
  height: 67px;
  bottom: -67px;
  animation-delay: 3s;
}
.background li:nth-child(5) {
  left: 26%;
  width: 53px;
  height: 53px;
  bottom: -53px;
  animation-delay: 25s;
}
.background li:nth-child(6) {
  left: 73%;
  width: 60px;
  height: 60px;
  bottom: -60px;
  animation-delay: 9s;
}
.background li:nth-child(7) {
  left: 47%;
  width: 75px;
  height: 75px;
  bottom: -75px;
  animation-delay: 15s;
}
.background li:nth-child(8) {
  left: 13%;
  width: 39px;
  height: 39px;
  bottom: -39px;
  animation-delay: 29s;
}
.background li:nth-child(9) {
  left: 89%;
  width: 50px;
  height: 50px;
  bottom: -50px;
  animation-delay: 8s;
}