@use './base/base';

.nav{
  
  display: flex;
  flex-direction: row;
  .waz{
    position: fixed;
    top: 0;
    left: 0;
    width: 6em;
    height: 6em;
    background: linear-gradient(135deg, var(--Color2) 50% , transparent 50%);
    text-shadow: 2px 2px 2px var(--Color3);
    z-index: 2000;
    >a{
      width: 100%;
      height: 100%;
      display: grid;
    place-content: center;
      text-decoration: none;
      color: #edeadf;
      font-size: 2em;
    font-weight: bold;
      transition: font-size .3s ease-in-out;
    }
    >a:hover{
      font-size: 2.2em;
    }
  }
  .nav-container{
    position:fixed;
  width: 100%;
  height: 3em;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  z-index: 1000;
  background-color: var(--Color1);
  /*backdrop-filter: blur(10px);*/
  >ul{
    margin-right: 4em;
    width: calc(50vw - 4em);
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    >li{
      width: auto;
      height: 100%;
      list-style: none;
      display: grid;
      place-content: center;
      >a::before{
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: #edeadf;
          bottom: 0px;
          left: 0;
          transform-origin: right;
          transform: scaleX(0);
          transition: transform .2s ease-in-out;
        }
      >a:hover::before{
        transform: scaleX(1);
        transform-origin: left;
      }
      >a{
        width: auto;
        padding: 5px;
        position: relative;
        text-decoration: none;
        color: #edeadf;
        font-family: var(--font-Inconsolata);
        font-weight: 600;
        transform: translateX(0px);
        transition: transform,color .2s ease-in-out;
      }
      >a:hover{
        transform: translateX(0px);
        color: #edeadf;
      }

      
    }
  }
  .menu-boton{
    display: none;
    padding: 1em;
    button{
      background:none;
      border: 0;
      cursor: pointer;
      padding: 0;
      width: 60px;
      height: 60px;
      span{
        border-radius: 5px;
        background-color: white;
        display: block;
        width: 60%;
        margin: 5px auto;
        height: 2px;
        animation-duration: 1s;
        animation-fill-mode: forwards;
      }

      .top-line{
        animation-name: top-line-animation-close;
      }
      .middle-line{
        animation-name: middle-line-animation-close;
      }
      .bottom-line{
        animation-name: bottom-line-animation-close;
      }
    }
    button.open{
      .top-line{
        animation-name: top-line-animation;
      }
      .middle-line{
        animation-name: middle-line-animation;
      }
      .bottom-line{
        animation-name: bottom-line-animation;
      }
    }
    
  }
  }
  .nav-despl{
  position:fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1500;
  background-color: var(--Color1);
  >ul{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 1em;
    >li{
      list-style: none;
      >a{
        position: relative;
        text-decoration: none;
        color: #edeadf;
        font-family: var(--font-Inconsolata);
        font-weight: 600;
        font-size: 1.5em;
      }

      >a:hover{
        text-decoration:underline;
        color: var(--Color4);
      }
    }
  }
  }
  
}

@keyframes top-line-animation{
  50%, 100%{
    margin: 0 auto;
    transform: translateY(2px) rotate(-45deg);
  }
}
@keyframes bottom-line-animation{
  50%, 100%{
    margin: 0 auto;
    transform: translateY(-2px) rotate(45deg);
  }
}
@keyframes middle-line-animation{
  20%, 100%{
    margin: 0 auto;
    width: 0;
    opacity: 0;
  }
}
@keyframes top-line-animation-close{
  0%{
    margin: 0 auto;
    transform: translateY(2px) rotate(-45deg);
  }
  50%, 100%{
    margin: 5px auto;
    transform: translateY(0px) rotate(0deg);
  }
}
@keyframes middle-line-animation-close{
  0%{
    margin: 0 auto;
    width: 0;
    opacity: 0;
  }
  20%, 100%{
    margin: 0 auto;
    width: 60%;
    opacity: 1;
  }
}
@keyframes bottom-line-animation-close{
  0%{
    margin: 0 auto;
    transform: translateY(-2px) rotate(45deg);
  }
  50%, 100%{
    margin: 5px auto;
    transform: translateY(0px) rotate(0deg);
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px){
  .nav{
    .nav-container{
      position:fixed;
    width: 100%;
    height: 3em;
    display: flex;
    flex-direction: row;
    justify-content: end;
    z-index: 1000;
    background-color: var(--Color1);
    >ul{
      margin-right: 4em;
      width: calc(60vw - 4em);
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      >li{
        width: auto;
        height: 100%;
        list-style: none;
        display: grid;
        place-content: center;
        >a::before{
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #edeadf;
            bottom: 0px;
            left: 0;
            transform-origin: right;
            transform: scaleX(0);
            transition: transform .2s ease-in-out;
          }
        >a:hover::before{
          transform: scaleX(1);
          transform-origin: left;
        }
        >a{
          width: auto;
          padding: 5px;
          position: relative;
          text-decoration: none;
          color: #edeadf;
          font-family: var(--font-Inconsolata);
          font-size: 14px;
          font-weight: 600;
          transform: translateX(0px);
          transition: transform,color .2s ease-in-out;
        }
        >a:hover{
          transform: translateX(0px);
          color: #edeadf;
        }
  
        
      }
    }
    .menu-boton{
      display: none;
      padding: 1em;
    }
    }
    .nav-despl{
      position:fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 1500;
      background-color: var(--Color1);
      >ul{
        margin-right: 4em;
        width: calc(50vw - 4em);
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        >li{
          width: auto;
          height: 100%;
          list-style: none;
          display: grid;
          place-content: center;
          >a::before{
              content: "";
              position: absolute;
              width: 100%;
              height: 2px;
              background-color: #edeadf;
              bottom: 0px;
              left: 0;
              transform-origin: right;
              transform: scaleX(0);
              transition: transform .2s ease-in-out;
            }
          >a:hover::before{
            transform: scaleX(1);
            transform-origin: left;
          }
          >a{
            width: auto;
            padding: 5px;
            position: relative;
            text-decoration: none;
            color: #edeadf;
            font-family: var(--font-Inconsolata);
            font-weight: 600;
            transform: translateX(0px);
            transition: transform,color .2s ease-in-out;
          }
          >a:hover{
            transform: translateX(0px);
            color: #edeadf;
          }
    
          
        }
      }
      }
  }
  
}

@media screen and (max-width: 767px) {
  .nav{
    .nav-container{
      position:fixed;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: end;
    z-index: 1000;
    background-color: var(--Color1);
    >ul{
    margin-right: 4em;
    width: calc(50vw - 4em);
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    >li{
      width: auto;
      height: 100%;
      list-style: none;
      display: none;
      place-content: center;
      >a::before{
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: #edeadf;
          bottom: 0px;
          left: 0;
          transform-origin: right;
          transform: scaleX(0);
          transition: transform .2s ease-in-out;
        }
      >a:hover::before{
        transform: scaleX(1);
        transform-origin: left;
      }
      >a{
        width: auto;
        padding: 5px;
        position: relative;
        text-decoration: none;
        color: #edeadf;
        font-family: var(--font-Inconsolata);
        font-weight: 600;
        transform: translateX(0px);
        transition: transform,color .2s ease-in-out;
      }
      >a:hover{
        transform: translateX(0px);
        color: #edeadf;
      }

      
    }
  }
    .menu-boton{
      display: flex;
    }
    
    }
    .nav-despl{
      position:fixed;
      margin-top: 3em;
      top: 0;
      left: 100%;
      width: 100%;
      height: calc(100% - 3em);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 100;
      background-color: #000000e7;
      transition: left .3s ease-in-out;
      >ul{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        gap: 1em;
        >li{
          list-style: none;
          >a{
            position: relative;
            text-decoration: none;
            color: #edeadf;
            font-family: var(--font-Inconsolata);
            font-weight: 600;
            font-size: 1.5em;
          }
    
          >a:hover{
            text-decoration:underline;
            color: var(--Color4);
          }
        }
      }
      }

      .activo{
        display: flex;
        top: 0;
        left: 0;
      }

  }
  
}