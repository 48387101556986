@use './base/base';

.contact{
  width: 100vw;
  height: calc(100vh + auto); 
  font-family: var(--font-Inconsolata);
  display: grid;
  place-content: center;
  padding-top: 3em;
  margin-bottom: 3em;
  .cont-contact{
    position: relative;
    width: 75vw;
    height: auto;
    padding: 3em 0;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    flex-wrap: wrap;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    .contact-titulo{
      width: 75vw;
      display: flex;
      justify-content: center;
      >h1{
        width: max-content;
        color: var(--Color4);
        border-bottom: 1px solid var(--Color4);
        margin-bottom: 1em;
      }
    }
    .formulario{
      form{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: .5em 1em;
        gap: .5em;
        label{
          width: 100%;
          color: var(--Color4);
          font-family: var(--font-Inconsolata);
          font-size: 1.2em;
        }
        input{
          width: 500px;
          padding: 10px;
          outline: none;
          font-family: var(--font-Inconsolata);
          font-size: 1.1em;
          margin-bottom: .2em;
          color: var(--Color5);
          background-color: transparent;
          border-top: 1px solid var(--Color3);
          border-left: 1px solid var(--Color3);
          border-right: none;
          border-bottom: none;
          transition: all .2s ease-in;
        }
        #nameCli:focus,#mailCli:focus{
          box-shadow: 3px 3px 0px var(--Color3);
          background-color:#131a1fb9;
        }
        textarea{
          width: 500px;
          padding: 10px;
          resize: none;
          outline: none;
          font-family: var(--font-Inconsolata);
          font-size: 1em;
          color: var(--Color5);
          border-top: 1px solid var(--Color3);
          border-left: 1px solid var(--Color3);
          border-right: none;
          border-bottom: none;
          background-color: transparent;
          transition: all .2s ease-in;
        }
        #msgCli:focus{
          box-shadow: 3px 3px 0px var(--Color3);
          background-color:#131a1fb9;
        }
        #btn-enviar{
          font-family: var(--font-Inconsolata);
          font-size: 1.2em;
          font-weight: bold;
          background-color: var(--Color3);
          color: var(--Color4);
          cursor: pointer;
          transition: all .2s ease-in;
        }
        #btn-enviar:hover{
          box-shadow: 3px 3px 0px var(--Color4);
        }
      }
      >a{
        width: 100px;
        color: white;
        font-size: 1em;
        opacity: 0;
      }
    }
  }
  .cont-contact::before,.cont-contact::after{
    content: "";
    position: absolute;
    width: 60px;
    height: 60px;
    
  }
  .cont-contact::before{
    top: 0;
    left: 0;
    border-top: 1px solid var(--Color3); 
    border-left: 1px solid var(--Color3);
  }
  .cont-contact::after{
    bottom: 0;
    right: 0;
    border-bottom: 1px solid var(--Color3); 
    border-right: 1px solid var(--Color3);
  }
}

@media screen and (max-width: 767px) {

  .contact{
    .cont-contact{
    position: relative;
    width: 95vw;
    height: auto;
    padding: 3em 0;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    .contact-titulo{
      width: 90vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      >h1{
        width: 100%;
        color: var(--Color4);
        border-bottom: 1px solid var(--Color4);
        margin-bottom: 1em;
        text-align: center;
      }
    }
    .formulario{
      width: 100%;
      form{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        padding: .5em;
        gap: .5em;
        label{
          width: 100%;
          color: var(--Color4);
          font-family: var(--font-Inconsolata);
          font-size: 1.2em;
        }
        input{
          width: auto;
          min-width: 100%;
          padding: 10px;
          outline: none;
          font-family: var(--font-Inconsolata);
          font-size: 1.1em;
          margin-bottom: .2em;
          color: var(--Color5);
          background-color: transparent;
          border-top: 1px solid var(--Color3);
          border-left: 1px solid var(--Color3);
          border-right: none;
          border-bottom: none;
          transition: all .2s ease-in;
        }
        #nameCli:focus,#mailCli:focus{
          box-shadow: 0px 2px 0px var(--Color3);
        }
        textarea{
          width: auto;
          min-width: 100%;
          padding: 10px;
          resize: none;
          outline: none;
          font-family: var(--font-Inconsolata);
          font-size: 1em;
          color: var(--Color5);
          border-top: 1px solid var(--Color3);
          border-left: 1px solid var(--Color3);
          border-right: none;
          border-bottom: none;
          background-color: transparent;
          transition: all .2s ease-in;
          
        }
        #btn-enviar{
          font-family: var(--font-Inconsolata);
          font-size: 1.2em;
          font-weight: bold;
          background-color: var(--Color3);
          color: var(--Color4);
          cursor: pointer;
        }
      }
      >a{
        width: 100px;
        color: white;
        font-size: 1em;
        opacity: 0;
      }
    }
    }
  }
}