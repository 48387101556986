@use './base/base';

.hab{
  width: 100vw;
  height: calc(100vh + auto);
  font-family: var(--font-Inconsolata);
  display: grid;
  place-content: center;
  padding-top: 3em;
  .cont-hab{
    position: relative;
    padding: 3em;
    width: 75vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    color: var(--Color4);
    gap: 1.5em;
    .hab1{
      width: 100%;
      height: auto;
      display: flex;
      .hab-type{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 1.5em;
        >h1{
          width: max-content;
          border-bottom: 1px solid var(--Color4);
        }
        >ul{
          display: flex;
          flex-direction: column;
          gap: 1em;
          >li{
            font-size: 18px;
          }
        }
      }
      .hab-type-explain{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        >img{
          width: 80%;
          height: auto;
          object-fit: cover;
          border-radius:5px;
        }
      }
    }
    .skills{
      width: 100%;
      height: auto;
      margin-top: 8em;
      margin-bottom: 8em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2em;
      >h1{
        text-align: center;
        color: var(--Color4);
      }
      >ul{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        >li{
          width: 5em;
          height: 5em;
          border-radius: 50%;
          display: grid;
          place-items: center;
          background-color: white;
          >i{
            font-size: 3em;
            text-shadow: 0px 1px 1px #23252e;
          }
          #html5{
            color: orangered;
          }
          #css3{
            color: #0883d8;
          }
          #sass{
            color: #bf4080;
          }
          #javascript{
            color: gold;
          }
          #bootstrap{
            color: purple;
          }
          #git{
            color: tomato;
          }
          #github{
            color: #16171d;
          }
          #reactjs{
            color: skyblue;
          }
        }
      }
      
    }
    
    
  }

  .cont-hab::before,.cont-hab::after{
    content: "";
    position: absolute;
    width: 60px;
    height: 60px;
    
  }
  .cont-hab::before{
    top: 0;
    left: 0;
    border-top: 1px solid var(--Color3); 
    border-left: 1px solid var(--Color3);
  }
  .cont-hab::after{
    bottom: 0;
    right: 0;
    border-bottom: 1px solid var(--Color3); 
    border-right: 1px solid var(--Color3);
  }
}

#html5{
  color: orangered;
}
#css3{
  color: #0883d8;
}
#sass{
  font-size: 2.5em !important;
  color: #bf4080;
}
#javascript{
  color: gold;
}
#bootstrap{
  color: purple;
}
#git{
  color: tomato;
}
#github{
  color: #16171d;
}
#reactjs{
  color: skyblue;
}


@media screen and (min-width: 768px) and (max-width: 1023px){
  .hab{
    .cont-hab{
      position: relative;
      padding: 2em;
      width: 90vw;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: var(--Color4);
      gap: 1.5em;
      .hab1{
        .hab-type{
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          gap: 1.5em;
          >h1{
            font-size: 1.8em;
            width: max-content;
            border-bottom: 1px solid var(--Color4);
          }
          >ul{
            display: flex;
            flex-direction: column;
            gap: 1em;
            >li{
              font-size: 1em;
            }
          }
        }
        .hab-type-explain{
          width: 50%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          >img{
            object-fit: cover;
          }
        }
      }
      .skills{
        >h1{
          font-size: 1.8em;
          text-align: center;
          color: var(--Color4);
        }
        >ul{
          display: flex;
          justify-content: center;
          align-items: center;
          >li{
            width: 3.5em;
          height: 3.5em;
          border-radius: 50%;
          display: grid;
          place-items: center;
          background-color: white;
            >i{
              font-size: 2em;
              text-shadow: none;
            }
          }
        }
        
      }
      
    }
    .cont-hab::before,.cont-hab::after{
      content: "";
      position: absolute;
      width: 40px;
      height: 40px;
      
    }
    
  }
  #sass{
    font-size: 1.5em !important;
    color: #bf4080;
  }
}

@media screen and (max-width: 767px) {
  .hab{
    .cont-hab{
      position: relative;
      padding: 2em;
      width: 95vw;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: var(--Color4);
      gap: 1.5em;
      .hab1{
        .hab-type{
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          gap: 1.5em;
          >h1{
            font-size: 1.5em;
            width: max-content;
            border-bottom: 1px solid var(--Color4);
          }
          >ul{
            display: flex;
            flex-direction: column;
            gap: 1em;
            >li{
              font-size: 14px;
            }
          }
        }
        .hab-type-explain{
          display: none;
        }
      }
      .skills{
        >h1{
          font-size: 1.5em;
          text-align: center;
          color: var(--Color4);
        }
        >ul{
          display: flex;
          justify-content: center;
          align-items: center;
          >li{
            width: 2.5em;
            height: 2.5em;
            border-radius: 50%;
            display: grid;
            place-items: center;
            background-color: white;
            >i{
              font-size: 1.8em;
              text-shadow: none;
            }
          }
        }
        
      }
      
    }
    .cont-hab::before,.cont-hab::after{
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      
    }
    
  }
  #sass{
    font-size: 1.3em !important;
    color: #bf4080;
  }
}