@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;500;600;700&display=swap');

:root{
  /*Paleta de Colores*/
  --Color1:#000000;
  --Color2:#0B60B0;
  --Color3:#40A2D8;
  --Color4:#F0EDCF;
  --Color5:#BBE1FA;

  /*Fuente*/
  --font-Inconsolata:'Inconsolata', monospace;
}
