.footer{
  position: absolute;
  width: 100%;
  height: 4em;
  display: flex;
 flex-direction: row;
 justify-content: space-around;
 align-items: center;
  padding: .5em;
  color: var(--Color4);
  font-family: var(--font-Inconsolata);
  background-color: var(--Color2);
  font-size: 1.2em;
}
@media screen and (min-width: 768px) and (max-width: 1023px){

.footer{
  font-size: 1em;
  >p{
    font-size: 1em;
  }
}
}

@media screen and (max-width: 767px) {
  .footer{
    font-size: .8em;
    text-align: center;
    text-wrap: wrap;
    >p{
      font-size: 1em;
    }
  }
}